import React, { Component } from "react";
import { HeaderText } from "../../elements";

export default class Header extends Component {
  render() {
    return (
      <HeaderText>
        Gempuzzle
      </HeaderText>
    );
  }
}
